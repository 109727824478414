import {HeadingDiv, LockDiv, SingleActionScreen} from "../components/divs";
import {MainText, OptionText} from "../components/texts";
import unlock from '../svgs/unlock.svg';
import lock from '../svgs/lock.svg';
import {OptionButton} from "../components/buttons";
import { useNavigate } from "react-router-dom";

const Unlock = () => {
    const navigate = useNavigate()
    return(
        <SingleActionScreen>
            <HeadingDiv>
                <MainText>
                    Unlock your medicine cabinet
                </MainText>
            </HeadingDiv>
            <LockDiv>
                <div>
                    <img src={lock} width={'70%'} height={'100%'} style={{maxWidth:'400px'}} alt={"cart icon"}></img>
                </div>
                <OptionButton onClick={()=>{ navigate("/scan");}}>
                    <div>
                        <img src={unlock} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"unlock icon"}></img>
                    </div>
                    <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%" }}>
                        <OptionText>
                            Unlock
                        </OptionText>
                    </div>
                </OptionButton>
            </LockDiv>
        </SingleActionScreen>
    )
}

export default Unlock;