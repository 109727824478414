import {
    HeadingDiv,
    MultiActionScreen, OptionsDiv,
    ReceiptDiv
} from "../components/divs";
import {MainText, OptionText} from "../components/texts";
import {OptionButton} from "../components/buttons";
import email from "../svgs/email.svg";
import cont from "../svgs/continue.svg";
import { Document, Page } from "react-pdf";
import {useNavigate} from "react-router-dom";
import {CustomDialog} from "../components/dialog";
import {useState} from "react";

const DispensedSummary = ({receipt, setReceipt}) => {
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState(null)
    const [dialogDescription, setDialogDescription] = useState(null)
    const [dialogAction, setDialogAction] = useState(null)
    const proceedToMenu = ()=>{
        navigate('/access-menu')
    }
    const triggerEmail = () =>{
        setDialogAction('email')
        setDialogTitle("Email")
        setDialogDescription(`Receipt has been successfully emailed to the office`)
        setOpenDialog(true)
    }
    const onEmail = ()=> {
    }
    const getAction = (action) =>{
        switch (action){
            case 'email':
                return onEmail;
            default:
                return ()=>{console.log("No action")}
        }
    }
    return(
        <MultiActionScreen>
           <HeadingDiv>
                <MainText>
                    Dispensed Summary
                </MainText>
            </HeadingDiv>
            <ReceiptDiv>
                <div className={'Preview__container__document'}>
                  <Document file={receipt}>
                    <Page  pageNumber={1} renderTextLayer={false}/>
                  </Document>
                </div>
                <OptionsDiv>
                    <OptionButton onClick={()=>{triggerEmail()}}>
                        <div>
                            <img src={email} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"add icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                                Share
                            </OptionText>
                        </div>
                    </OptionButton>
                    <OptionButton onClick={()=>{proceedToMenu()}} color={'inverse'}>
                        <div>
                            <img src={cont} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"cancel icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                               Proceed to menu
                            </OptionText>
                        </div>
                    </OptionButton>
                </OptionsDiv>
            </ReceiptDiv>
            <CustomDialog
                type={'alert'}
                open={openDialog}
                setOpen={setOpenDialog}
                title={dialogTitle}
                description={dialogDescription}
                onConfirm={getAction(dialogAction)}/>
        </MultiActionScreen>
    )
}

export default DispensedSummary;