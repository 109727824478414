import {styled} from "../stitches.config";

export const OptionButton = styled(
    'button',{
        display: 'flex',
        alignItems:"center",
        width: '100%',
        height: '50px',
        textAlign:'center',
        borderRadius: '20px',
        paddingY: '15px',
        marginY: '15px',
        '&:active':{
                border:'2px solid $secondary',
                transform:'scale(0.98)'
        },
        variants:{
            color:{
                normal:{
                    backgroundColor: '$light',
                    border:'2px solid $light',
                },
                inverse:{
                    backgroundColor: 'white',
                    border:'2px solid $secondary',
                }
            }
        },
        defaultVariants: {
            color: 'normal'
        }

    }
)

export const SplashButton = styled(
    'button',
    {
        display: 'flex',
        padding: 'auto',
        width: '100%',
        height: '45px',
        backgroundColor: '$secondary',
        textAlign:'center',
        borderRadius: '15px',
        border: '0px',
        '&:active':{
        transform:'scale(0.98)'
        }
    }
)

export const DialogButton = styled(
    'button',
    {
        display: 'block',
        width: '60px',
        height: '25px',
        backgroundColor: '$secondary',
        alignItems: 'center',
        textAlign:'center',
        borderRadius: '10px',
        border: '0px',
        '&:active':{
        transform:'scale(0.98)'
        }
    }
)

export const IconButton = styled(
    'button',{
            displayed:'block',
            width:'fit-content',
            height:'fit-content',
            backgroundColor: 'transparent',
            textAlign: 'center',
            alignItems: 'center',
                border: '0px',
                '&:active':{
                transform:'scale(0.90)'
                }

    }
)