import {ErrorDiv, HeadingDiv, QRDiv, SingleActionScreen} from "../components/divs";
import {ErrorText, MainText, OptionText} from "../components/texts";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import view_cart from "../svgs/cart.svg";
import {OptionButton} from "../components/buttons";

import {CustomDialog} from "../components/dialog";
import {getTotalCartCount} from "../utils";
import {QrReader} from "react-qr-reader";

const Scan = ({setScannedItem, scannedItem, cart, nappiDescriptions, itemQuantitiesToDispense,
              medicineInventory, setSnackOpen, setAlertProps}) => {
    const [scan, setScan] = useState(true)
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState(null)
    const [dialogDescription, setDialogDescription] = useState(null)
    const [invalidReason, setInvalidReason] = useState({})
    useEffect(() => {
        if(!invalidReason?.reason){
            return
        }
        switch (invalidReason.reason) {
            case 'Format':
                setDialogTitle('Invalid QR code')
                setDialogDescription('The QR code provided does not match the required format')
                break;
            case 'Stock':
                setDialogTitle('Out of stock')
                setDialogDescription(`${invalidReason.meta.medicine} is no longer in stock`)
                break;
            case 'MaxStock':
                setDialogTitle('Out of stock')
                setDialogDescription(`Maximum supply of ${invalidReason.meta.medicine} has already been added to cart`)
                break;
            default:
                console.error(`Case for error ${invalidReason} has not been considered`)
        }
        }
        ,[invalidReason]
    )
     const isValidMedicine = (medicineData) => {
         const valid_keys = ["name", "nappi_code", "quantity", "manufacturer"]
         valid_keys.forEach((key_name)=>{
             if(!(key_name in medicineData)){
                 setInvalidReason({reason:"Format"})
                 throw new Error("Invalid medicine")
             }
         })
         return true
     }
     const isInInventory = (medicineData) => {
        const medicine_identifiers = Object.keys(medicineInventory)
        if(medicineInventory[medicineData.nappi_code]===0){
            setInvalidReason(
                {
                    reason:"Stock",
                    meta:{medicine: `${medicineData.name} (${medicineData.nappi_code})`
                    }
                })
            return false
        }
        if(cart.items[medicineData.nappi_code] && cart.items[medicineData.nappi_code]['qty']+1 > medicineInventory[medicineData.nappi_code]){
            setInvalidReason(
                {
                    reason:"MaxStock",
                    meta:{medicine: `${medicineData.name}(${medicineData.nappi_code})`
                    }
                })
            return false
        }

        if(!medicine_identifiers.includes(medicineData.nappi_code)){
            setInvalidReason(
                {
                    reason:"Stock",
                    meta:{medicine: `${medicineData.name}(${medicineData.nappi_code})`
                    }
                })
            return false
        }
        return true
     }
     const isQRValid = (QRInput)=>{
         try{
            const qrData = JSON.parse(QRInput.text)
            const isValid =  isValidMedicine(qrData)
            const isInStock = isInInventory(qrData)
            return isValid&&isInStock

         }
         catch (e){
             console.error(e)
             setInvalidReason({reason:"Format"})
             return false
         }
     }
     const navigate = useNavigate()
      const handleScan = (result) => {
          if(result!==null){
              const isResultValid = isQRValid(result)
              if(isResultValid){
                  JSON.parse(result.text)
                  let item = JSON.parse(result.text)
                  item['description'] = nappiDescriptions[item.nappi_code]
                  setScannedItem(item)
                  setScan(false)
                  navigate('/scanned-item')
              }
              else{
                  setScan(false)
                  setOpenDialog(true)
              }
          }
          else{
              navigate('/scan')
              setScannedItem({})
          }
      }
      const checkoutSummary = ()=>{
         navigate('/scanned-summary')
      }
    return(
        <SingleActionScreen>
            <HeadingDiv>
                <MainText>
                    Scan medicine
                </MainText>
            </HeadingDiv>
            <QRDiv>
                <div></div>
                <div style={{
                    display:'block',
                    justifyContent: "center",
                    alignItems:'center',
                    alignContent: 'center',
                    width: '100%',
                    height:'100%',
                    textAlign:"center",}}
                >
                    {scan?
                        <QrReader
                        delay={10}
                        onResult={(result, error) => {
                            if(!!result){
                                handleScan(result)
                            }
                          if (!!error) {
                            // console.error(error);
                          }
                            }
                        }
                        style={{
                                display:'block',
                                borderRadius:'10px',
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems:'center',
                                textAlign:"center",
                                height:'auto',
                                width:"100%",
                                maxWidth:'1000px',
                                margin:'auto'
                            }}
                        constraints={{
                          facingMode: 'environment'
                        }}
                      />
                      :
                      null
                    }

                    {getTotalCartCount(cart)>0?
                    <OptionButton onClick={()=>{checkoutSummary()}}>
                        <div>
                            <img src={view_cart} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"scan icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                                View Cart
                            </OptionText>
                        </div>
                    </OptionButton>
                    :
                    null

                    }
                </div>

                <div></div>
            </QRDiv>
            <CustomDialog
                type={'alert'}
                open={openDialog}
                setOpen={setOpenDialog}
                title={dialogTitle}
                description={dialogDescription}
                onConfirm={()=>{setScan(true)}}/>
        </SingleActionScreen>
    )
}

export default Scan;