import {HeadingDiv, MenuDiv, MenuScreen} from "../components/divs";
import {MainDescriptor, MainText} from "../components/texts";
import {useState} from "react";
import {OptionButton} from "../components/buttons";
import {OptionText} from "../components/texts"
import cabinet from '../svgs/cabinet.svg';
import receipts from '../svgs/receipts.svg';
import inventory from '../svgs/inventory.svg';
import {useNavigate} from "react-router-dom";

const MainMenu = ({doctorName}) => {
    const navigate = useNavigate()
    const beginDispenseWorkflow = ()=>{
        navigate('/unlock')
    }
    return(
        <MenuScreen>
            <HeadingDiv>
                <div>
                    <MainText>
                        Welcome Dr {doctorName}
                    </MainText>
                    <MainDescriptor>
                        How can we help?
                    </MainDescriptor>
                </div>
            </HeadingDiv>
            <MenuDiv>
                <OptionButton onClick={()=>{beginDispenseWorkflow()}}>
                    <div>
                        <img src={cabinet} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"scan icon"}></img>
                    </div>
                    <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                        <OptionText>
                           Dispense Medication
                        </OptionText>
                    </div>
                </OptionButton>
                <OptionButton>
                    <div>
                        <img src={receipts} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"receipts icon"}></img>
                    </div>
                    <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                        <OptionText>
                           View my Receipts
                        </OptionText>
                    </div>
                </OptionButton>

                <OptionButton>
                    <div>
                        <img src={inventory} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"inventory icon"}></img>
                    </div>
                    <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                        <OptionText>
                           View Stock Levels
                        </OptionText>
                    </div>
                </OptionButton>

            </MenuDiv>
        </MenuScreen>
    )
}

export default MainMenu;

