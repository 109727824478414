import {createStitches} from "@stitches/react";

export const {styled, createTheme} = createStitches({
    theme:{
        colors:{
            primary: '#115E38',
            secondary: '#4DD07F',
            dark: '#141922',
            light: '#EEFBF2',
            darkgray: '#8F8F97',
            lightgray: '#F2F2F2',
            red: '#d62828'
        },
        fonts:{
            primary: 'Source Sans Pro',
            secondary: 'Quicksand'
        },
        fontSizes:{
            main: '1.25rem',
            lg: '1.125rem',
            md: '1rem',
            sm: '0.875rem',
            xsm: '0.75rem',
        }
    },
utils:{
      marginX: (value)=>({
          marginLeft: value,
          marginRight: value,

      }),
      marginY: (value)=>({
          marginBottom: value,
          marginTop: value,
      }),
      paddingX: (value)=>({
          paddingLeft: value,
          paddingRight: value,


      }),
      paddingY: (value)=>({
          paddingBottom: value,
          paddingTop: value,
      })
}
})
