import {styled} from "../stitches.config";

export const MainText = styled('p', {
    color: 'white',
    fontFamily: '$primary',
    fontSize: '$main',
    fontWeight: '600',
})

export const MainDescriptor = styled('p', {
    color: 'white',
    fontFamily: '$primary',
    fontSize: '$md',
    fontWeight: '200',
})

export const OptionText = styled('p', {
    color: '$dark',
    fontFamily: '$primary',
    fontSize: '$md',
    fontWeight: '600',
})

export const SplashHeading = styled('p', {
    color: '$dark',
    fontFamily: '$primary',
    fontSize: '$lg',
    fontWeight: '600',
})

export const SplashText= styled('p', {
    color: '$darkgray',
    fontFamily: '$primary',
    fontSize: '$md',
    fontWeight: '600',
})

export const SplashTextSmall=styled('p', {
    color: 'white',
    fontFamily: '$primary',
    fontSize: '$sm',
    fontWeight: '600',
    textAlign: 'centre'
})

export const ErrorText=styled('p', {
    color: '$red',
    fontFamily: '$primary',
    fontSize: '$xsm',
    fontWeight: '200',
    textAlign: 'centre'
})

export const ItemHeading=styled('p', {
    color: '$dark',
    fontFamily: '$secondary',
    variants:{
        size: {
            md: {
                fontSize: '$md',
                fontWeight: '200',
            },
            sm: {
                fontSize: '$sm',
                fontWeight: '200',
            }
        },
        position:{
            left:{
               textAlign: 'left',
            },
            center:{
               textAlign: 'center',
            }
        }
    },
    defaultVariants: {
        size: 'md',
        position: 'left'
    }
})

export const ItemText=styled('p', {
    color: '$darkgray',
    fontFamily: '$secondary',
    textAlign: 'left',
    variants:{
        size: {
            md: {
                fontSize: '$sm',
                fontWeight: '500',
            },
            sm: {
                fontSize: '$xsm',
                fontWeight: '500',
            }
        }
    },
    defaultVariants: {
        size: 'md'
    }
})

export const DialogText=styled('p', {
    color: '$darkgray',
    fontFamily: '$secondary',
    fontSize: '$md',
    fontWeight: '600',
})