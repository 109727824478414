import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DialogText, ItemHeading, ItemText, SplashTextSmall} from "./texts";
import {DialogButton} from "./buttons";
import {reFormat} from "../utils";

export const CustomDialog = ({title, type, description, open, setOpen, onCancel, onConfirm}) =>{
  if(type!=="confirmation" && type!=="alert"){
    throw new Error(`Invalid prop ${type} for prop 'type'. Acceptable props: confirmation|alert`)
  }
  const onClose = () => {
      setOpen(false)
  }
  const handleCancel = () => {
    onClose()
    if(onCancel){
      onCancel()
    }
  };

  const handleOk = () => {
    onConfirm()
    onClose()
  };


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent >
        <DialogText>
            {description}
        </DialogText>
      </DialogContent>
      <DialogActions>
        {
          type==="confirmation"?
            <DialogButton onClick={handleCancel}>
              <SplashTextSmall>Cancel</SplashTextSmall>
            </DialogButton>
          :null
        }
        <DialogButton onClick={handleOk}>
           <SplashTextSmall>{type==="confirmation"?'Yes':'OK'}</SplashTextSmall>
        </DialogButton>
      </DialogActions>
    </Dialog>
  )
}