import {SplashScreen, SplashActionDiv} from "../components/divs";
import {SplashHeading, SplashText, SplashTextSmall} from "../components/texts";
import {SplashButton} from "../components/buttons";
import splash from '../svgs/splash.png';
import {useNavigate} from "react-router-dom";
const Splash = () => {
    const navigate = useNavigate()
    const exitSplash = ()=>{
        navigate('/login')
    }
    return(
        <SplashScreen>
            <div style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                <img src={splash} alt="Doctor's room" style={{ textAlign:"center", width:'100%', maxWidth:'500px', height:'auto'}}></img>
            </div>
            <SplashActionDiv>
                <SplashHeading>Manage your dispensary</SplashHeading>
                <SplashText>Hassle free dispensing for Doctors</SplashText>
                <SplashButton onClick={exitSplash}>
                    <div style={{display:'flex', justifyContent: "center", alignItems: 'center', textAlign:"center", width:"100%", paddingTop:'15px'}}>
                        <SplashTextSmall>
                            Get Started
                        </SplashTextSmall>
                    </div>
                </SplashButton>
            </SplashActionDiv>
        </SplashScreen>
    )
}

export default Splash;