import {HeadingDiv, ItemPreviewDiv, OptionsDiv, MultiActionScreen, ScannedItemDiv} from "../components/divs";
import {ItemHeading, ItemText, MainText, OptionText} from "../components/texts";
import {OptionButton} from "../components/buttons";
import cancel from "../svgs/cancel.svg";
import add from "../svgs/add.svg";
import {reFormat} from "../utils";
import {useNavigate} from "react-router-dom";
import {CustomDialog} from "../components/dialog";
import {useEffect, useState} from "react";


const ScannedItem = ({scannedItem, setScannedItem, setCart, cart, nappiDescriptions, setSnackOpen, setAlertProps}) => {
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState(null)
    const [dialogDescription, setDialogDescription] = useState(null)
    const [dialogAction, setDialogAction] = useState(null)

    useEffect(()=>{
        if(Object.keys(scannedItem).length===0 || scannedItem===null){
            setAlertProps({
                type:'error',
                message:'No item have been scanned. Please proceed to scan an item.'
            })
            setSnackOpen(true)
            navigate('/scan')
        }
    }, [])

    //Todo: set scanned item to empty dict; and navigate to scan screen
    const cancelItem = ()=>{
        setScannedItem({})
        navigate('/scan')
    }
    // Todo: Add items to dispense, set set scanned item back to empty dict, nav to scan screen
    const addItem = ()=>{
        let cartCopy = JSON.parse(JSON.stringify(cart))
        let medicineCopy = JSON.parse(JSON.stringify(scannedItem))
        medicineCopy['description'] = nappiDescriptions[scannedItem.nappi_code]
        cartCopy.items[scannedItem.nappi_code] = cartCopy.items[scannedItem.nappi_code] || {}
        cartCopy.items[scannedItem.nappi_code]['qty'] = cartCopy.items[scannedItem.nappi_code]['qty']+1 || 1
        cartCopy.items[scannedItem.nappi_code]['details'] = medicineCopy
        setCart(cartCopy)
        setScannedItem({})
        navigate('/scan')
    }

    const onConfirmCancelItem = ()=>{
        setDialogTitle("Discard scanned item");
        setDialogDescription("Are you sure you want to cancel this item?")
        setDialogAction('cancel-item')
        setOpenDialog(true)
    }

    const getAction = (action) =>{
        switch (action){
            case 'cancel-item':
                return cancelItem;
            default:
                return ()=>{console.log("No action")}
        }
    }


    return(
        <MultiActionScreen>
           <HeadingDiv>
                <MainText>
                    Scanned Medicine
                </MainText>
            </HeadingDiv>
            <ScannedItemDiv>
                <ItemPreviewDiv>
                    {
                        Object.keys((({name, nappi_code, quantity, description, manufacturer, price }) => ({name, nappi_code, quantity, description, manufacturer, price }))(scannedItem)).map((key)=>{
                            return(
                                <div key={key} style={{display:'grid', gridTemplateRows:'auto', gridTemplateColumns:'auto', margin:'5px'}}>
                                         <ItemHeading>{reFormat((key))}</ItemHeading>
                                         <ItemText>{`${key==='price'?'R':''}${scannedItem[key]}`}</ItemText>
                                </div>
                            )
                        })
                    }
                </ItemPreviewDiv>
                    <OptionsDiv>
                    <OptionButton onClick={()=>{onConfirmCancelItem()}}>
                        <div>
                            <img src={cancel} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"cancel icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                               Discard
                            </OptionText>
                        </div>
                    </OptionButton>
                    <OptionButton onClick={()=>{addItem()}} color={'inverse'}>
                        <div>
                            <img src={add} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"add icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                               Add to cart
                            </OptionText>
                        </div>
                    </OptionButton>
                </OptionsDiv>
            </ScannedItemDiv>
            <CustomDialog
                type={'confirmation'}
                open={openDialog}
                setOpen={setOpenDialog}
                title={dialogTitle}
                description={dialogDescription}
                onConfirm={getAction(dialogAction)}/>
        </MultiActionScreen>
    )
}

export default ScannedItem;