import fs from 'fs';
import PDFDocument from 'pdfkit';
import './registerStaticFiles';

export const reFormat = (text) => {
    const allUpper = ['nappi'];
    const allLower = ['and', 'the', 'on', 'of', 'with', 'without'];
    const textArray = text.split('_');
    const reformattedText = textArray.map((word) => {
        if (allUpper.includes(word.toLowerCase())) {
            return word.toUpperCase();
        }
        if (allLower.includes(word.toLowerCase())) {
            return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return reformattedText.join(' ');
};

export const getTotalCartCount = (cart)=>{
    let total = 0
    Object.keys(cart.items).forEach((nappi)=>{
        total = total + cart.items[nappi].qty??0
    })
    return total
}

 const twoDecimals = (num)=>{
    return (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2)
 }

 export const waitForData = async doc => {
  return new Promise((resolve, reject) => {
    const buffers = [];
    doc.on('data', buffers.push.bind(buffers));
    doc.on('end', async () => {
      const pdfBuffer = Buffer.concat(buffers);
      const pdfBase64 = pdfBuffer.toString('base64');
      resolve(`data:application/pdf;base64,${pdfBase64}`);
    });
    doc.on('error', reject);
  });
};

function createFetchError(fileURL, error) {
    const result = new Error(`Fetching "${fileURL}" failed: ${error}`);
    result.name = 'FetchError';
    return result;
}

export function fetchFile(fileURL, {type = 'arraybuffer'} = {}) {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', fileURL, true);
        request.responseType = type;

        request.onload = function (e) {
            if (request.status === 200) {
                resolve(request.response);
            } else {
                reject(createFetchError(fileURL, request.statusText));
            }
        };

        request.onerror = error => reject(createFetchError(fileURL, error));

        request.send();
    });
}


 export const createReceipt = (cart, setter)=>{
    const doc = new PDFDocument({size: 'A5', margins:{top:50, bottom:50, left:10, right:10}});
    const left= 0
    const itemIdent = 0
    const unitPriceIndent = 140
    const qtyIndent= 245
    const totalPriceIndent = 320
    const nappiIndent = 20
    const lg_font = 18
    const md_font = 17
    const sm_font = 16

    doc.image('images/logo.png', 0, 0, {
      width: '150',
      align: 'left',
      valign: 'left'
    });
    doc.moveDown(4)
    doc.fontSize(lg_font).font('Helvetica-Bold').text('Receipt of dispensed medications', {indent:left})
    doc.moveDown(1)
    doc.fontSize(md_font).font('Helvetica').text('Item', {indent:itemIdent,})
    doc.moveUp()
    doc.fontSize(md_font).font('Helvetica').text('Unit price(R)', {indent:unitPriceIndent,})
    doc.moveUp()
    doc.fontSize(md_font).font('Helvetica').text('Quantity', {indent:qtyIndent,})
    doc.moveUp()
    doc.fontSize(md_font).font('Helvetica').text('Total(R)', {indent:totalPriceIndent})

    let total_price = 0
    Object.keys(cart.items).forEach((item_nappi)=>{
        const item = cart.items[item_nappi]
        const itemName = item.details.name
        const itemPrice = item.details.price
        const itemQty = item.qty
        const itemTotal = itemPrice*itemQty
        total_price = total_price + itemTotal
        doc.moveDown(1)
        doc.fontSize(sm_font).font('Helvetica').text(itemName, {indent:itemIdent+5,})
        doc.moveUp()
        doc.fontSize(sm_font).font('Helvetica').text(twoDecimals(itemPrice), {indent:unitPriceIndent+5,})
        doc.moveUp()
        doc.fontSize(sm_font).font('Helvetica').text(itemQty, {indent:qtyIndent+5,})
        doc.moveUp()
        doc.fontSize(sm_font).font('Helvetica').text(twoDecimals(itemTotal), {indent:totalPriceIndent+5})
        doc.fontSize(sm_font).font('Helvetica').text(item_nappi, {indent:nappiIndent+5,})
    })
    doc.moveDown(2)
    doc.fontSize(md_font).font('Helvetica').text('Total(excluding VAT)', {indent:left,})
    doc.moveUp()
    doc.fontSize(sm_font).font('Helvetica').text(twoDecimals(total_price), {indent:totalPriceIndent+5,})
     doc.moveDown()
    doc.fontSize(md_font).font('Helvetica').text('VAT(15%)', {indent:left,})
    doc.moveUp()
    const vat = total_price*0.15.toFixed(2)
    doc.fontSize(sm_font).font('Helvetica').text(twoDecimals(vat), {indent:totalPriceIndent+5,})
    doc.moveDown()
    doc.fontSize(md_font).font('Helvetica').text('Total(including VAT)', {indent:left,})
    doc.moveUp()
    doc.fontSize(sm_font).font('Helvetica').text(twoDecimals(total_price+vat), {indent:totalPriceIndent+5,})
    waitForData(doc)
      .then(dataUrl => {
        // display the document in the iframe to the right
          setter(dataUrl)
      })
      .catch(error => {
          setter(null)
        console.log(error);
      });
     doc.end();
 }
