import {LoginScreen} from "../components/divs";
import idu_logo from "../svgs/IDU_LOGO.png"
import {SplashHeading, SplashTextSmall} from "../components/texts";
import {TextField} from "@mui/material";
import {SplashButton} from "../components/buttons";
import {useNavigate} from "react-router-dom";
const Login = ({setDoctorName}) => {
    const navigate = useNavigate()
    const login = ()=>{
        navigate('/access-menu')
    }
    return(
        <LoginScreen>
            <div style={{height:'100%', display:'flex'}}/>
            <div style={{width:'100%', display:'block'}}>
                <div style={{
                    display:'block',
                    justifyContent: 'center',
                    marginBottom:'25px',
                    alignContent: 'center',
                    alignItems: 'center',
                    gridTemplateColumns: '100%',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    textAlign:'center',
                    }}>
                    <img src={idu_logo} alt={"app_logo"} width={'50%'} height={'auto'} style={{maxWidth:'200px'}}/>
                    <SplashHeading>Intelligent Dispensing Unit</SplashHeading>
                </div>
                <div style={{
                    display:'grid',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    gridTemplateColumns: 'auto',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    textAlign:'center',
                    padding:"auto",
                    width:"100%",
                    height: 'auto'}}>

                    <TextField id="emailInput" label="Email" variant="standard"
                               sx={{
                                   marginBottom:'5px',
                                   marginTop:'5px',
                                   outline:'none',
                                   "& .MuiInputBase-root": {
                                        color: '#8F8F97',
                                    },
                                    "& .MuiFormLabel-root": {
                                        fontSize:'0.75rem',
                                        fontFamily: 'Source Sans Pro',
                                        fontWeight: '600',
                                        color: '#8F8F97',
                                    },
                                     "& .MuiFormLabel-root.Mui-focused": {
                                        color: '#8F8F97'
                                    },
                                   "& .MuiInput-underline:before": { borderBottom:'1px solid #F2F2F2' },
                                    "& .MuiInput-underline:after": {borderBottom:'1px solid #8F8F97'},
                                    "& .MuiInput-underline:hover:before": {borderBottom:'1px solid #8F8F97'},
                                }}/>
                    <TextField id="passwordInput" label="Password" variant="standard" type="password"
                               sx={{
                                   marginBottom:'5px',
                                   marginTop:'5px',
                                   outline:'none',
                                   "& .MuiInputBase-root": {
                                        color: '#8F8F97',
                                    },
                                    "& .MuiFormLabel-root": {
                                        fontSize:'0.75rem',
                                        fontFamily: 'Source Sans Pro',
                                        fontWeight: '600',
                                        color: '#8F8F97',
                                    },
                                     "& .MuiFormLabel-root.Mui-focused": {
                                        color: '#8F8F97'
                                    },
                                   "& .MuiInput-underline:before": { borderBottom:'1px solid #F2F2F2' },
                                    "& .MuiInput-underline:after": {borderBottom:'1px solid #8F8F97'},
                                    "& .MuiInput-underline:hover:before": {borderBottom:'1px solid #8F8F97'},
                                }}/>
                    <SplashButton style={{marginTop:'30px', marginBottom:'30px', width:'90vw', maxWidth: '400px'}} onClick={login}>
                    <div style={{display:'flex', justifyContent: "center", alignItems: 'center', textAlign:"center", width:"100%", paddingTop:'15px'}}>
                        <SplashTextSmall>
                            Login
                        </SplashTextSmall>
                    </div>
                    </SplashButton>
                </div>
            </div>
            <div style={{height:'100%', display:'flex'}}/>
        </LoginScreen>
    )
}
export default Login;