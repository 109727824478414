import {styled} from "../stitches.config";

export const SplashScreen = styled(
    'div',
    {
        display: 'grid',
        backgroundColor: 'transparent',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'auto 30%',
        width: '100vw',
        height:' 100vh',
    }
)

export const LoginScreen = styled(
    'div',
    {
        display: 'grid',
        backgroundColor: 'white',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'5% 70% auto',
        width: '100vw',
        height:' 100vh',

    }
)


export const MenuScreen = styled(
    'div',
    {
        display: 'grid',
        backgroundColor: 'transparent',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'15% auto',
        width: '100vw',
        height:' 100vh',
        alignItems: 'center',
        textAlign:'center',
    }
)
export const SingleActionScreen = styled(
    'div',
    {
        display: 'grid',
        backgroundColor: 'transparent',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'15% auto',
        width: '100vw',
        height:' 100vh',
        alignItems: 'center',
        textAlign:'center',
    }
)

export const MultiActionScreen = styled(
    'div',
    'div',
    {
        display: 'grid',
        backgroundColor: 'transparent',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'15% auto',
        width: '100vw',
        height:' 100vh',
        alignItems: 'center',
        textAlign:'center',
    }
)

export const MenuDiv = styled(
    'div',
    {
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
        width: '100%',
        height:' 100%',
        paddingX: '2.5%',
        paddingY: '15px',
    }
)

export const OptionsDiv = styled(
    'div',
    {
        backgroundColor: 'transparent',
        borderRadius: '25px 25px 0px 0px',
        width: '100%',
        height:' 100%',
        paddingX: '2.5%',
        paddingY: '5%',
    }
)
export const LockDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'auto',
        gridTemplateRows:'50% 15% auto',
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
        width: '100%',
        height:'100%',
                padding: '15px',
        marginY: '15px',
        alignItems: 'center',
        textAlign:'center',
        justifyContent: 'center',
        overflow: 'scroll'

    }
)

export const QRDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'20% auto 20%',
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
        width: '100%',
        height:'100%',
        padding: '15px',
        marginY: '15px',
        alignItems: 'center',
        textAlign:'center',
        justifyContent: 'center',
        overflow: 'scroll'
    }
)

export const ScannedItemDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'auto 45%',
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
        width:'100%',
        height:'100%',
        alignItems: 'center',
        textAlign:'center',
        justifyContent: 'center',
        overflow: 'scroll',
        padding: '15px',
        marginY: '15px',
    }
)

export const SummaryDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'60% auto',
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
         width:'100%',
        height:'100%',
        marginX: 'auto',
        paddingX: '5%',
        paddingY: '5%',
        textAlign:'center',
        justifyContent: 'center',
        overflow: 'scroll'
    }
)

export const ReceiptDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'80% auto',
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
         width:'100%',
        height:'100%',
        marginX: 'auto',
        paddingX: '5%',
        paddingY: '5%',
        textAlign:'center',
        justifyContent: 'center',
        overflow: 'scroll'
    }
)

export const SplashActionDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows: "repeat(3, 1fr)",
        backgroundColor: 'white',
        borderRadius: '25px 25px 0px 0px',
        width:'100%',
        height:'100%',
        marginX: 'auto',
        paddingX: '5%',
        paddingY: '10px',
        alignItems: 'center',
        textAlign:'center',
        justifyContent: 'center',
    }
)

export const HeadingDiv = styled(
    'div',
    {
            display: "grid",
            gridTemplateColumns: 'auto',
            gridTemplateRows: '100%',
            textAlign:'center',
            padding:'2%'
    }
)

export const ErrorDiv = styled(
    'div',
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        width: '100%',
        maxWidth: '500px',
        height: 'fit-content',
        backgroundColor: '$lightgray',
        textAlign:'center',
        borderRadius: '10px',
        marginY: '5px'
    }
)


export const ItemPreviewDiv = styled(
    'div',
    {
        display: 'block',
        alignItems:'center',
        width: '100%',
        height: '100%',
        maxHeight: '500px',
        backgroundColor: '$lightgray',
        textAlign:'left',
        borderRadius: '25px 25px 0px 0px',
        padding: '15px',
        marginY: '15px',
        overflow: 'scroll',
    }
)

export const SummaryItemPreviewDiv = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns : '60% 30% auto',
        gridTemplateRows : 'auto',
        alignItems:'center',
        width: '99%',
        height: 'fit-content',
        maxHeight: '100px',
        backgroundColor: '$lightgray',
        textAlign:'left',
        borderRadius: '15px',
        padding: '10px',
        marginBottom: '10px',
        marginX: 'auto',
        // '&:active':{
        // transform:'scale(0.98)'
        // },
    }
)

export const SummaryQuantityDiv = styled(
    'div',{
        display: 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gridTemplateRows : 'auto',
        alignItems:'center',
        width: 'fit-content',
        height: 'fit-content',
        maxHeight: '50px',
        backgroundColor: 'transparent',
        border: '0.0625rem solid $darkgray',
        textAlign:'left',
        borderRadius: '25px',
        padding: '5px',
        margin: 'auto'
    }
)

export const Header = styled(
    'div',{
        display:'grid',
        width:'100%',
        height: 'fit-content',
        gridTemplateColumns : '75% 1fr 1fr',
        gridTemplateRows : 'auto',
        backgroundColor: 'transparent',
        alignItems: 'center',
        alignText: 'center',
        justifyContent: 'center',
        paddingY: '10px',
        paddingX: '20px'
    }
)
