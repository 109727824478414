import {
    HeadingDiv,
    MultiActionScreen,
    OptionsDiv,
    SummaryDiv,
    SummaryItemPreviewDiv, SummaryQuantityDiv
} from "../components/divs";
import {ItemHeading, ItemText, MainText, OptionText} from "../components/texts";
import {useEffect, useState} from "react";
import {createReceipt, getTotalCartCount, reFormat} from "../utils";
import {IconButton, OptionButton} from "../components/buttons";
import bin from "../svgs/bin.svg";
import {CustomDialog} from "../components/dialog";
import scan from "../svgs/scan.svg";
import proceed from "../svgs/tick.svg";
import increment from "../svgs/increment.svg";
import decrement from "../svgs/decrement.svg";
import {useNavigate} from "react-router-dom";

const ScannedSummary = ({setCart, cart, setInventory, inventory,
                            setSnackOpen, setAlertProps, setReceipt}) => {
        const navigate = useNavigate()
        const [openDialog, setOpenDialog] = useState(false)
        const [dialogTitle, setDialogTitle] = useState(null)
        const [dialogDescription, setDialogDescription] = useState(null)
        const [dialogAction, setDialogAction] = useState(null)
        const [itemToView, setItemToView] = useState({})
        const [itemToRemove, setItemToRemove] = useState(null)
        useEffect(()=>{
            if(Object.keys(cart.items).length<=0){
                setAlertProps({
                    type:'error',
                    message:'Cart is empty. Please proceed to scan an item.'
                })
                setSnackOpen(true)
                navigate('/scan')
            }
        }, [])
        useEffect(()=>{
            if(Object.keys(itemToView).length!==0) {
                setDialogTitle("Item information");
                setDialogAction('item')
                setDialogDescription(
                    Object.keys((({name, nappi_code, quantity, description, manufacturer, price }) => ({name, nappi_code, quantity, description, manufacturer, price }))(itemToView)).map((key)=>{
                return(
                    <div key={key} style={{display:'grid', gridTemplateRows:'auto', gridTemplateColumns:'auto', margin:'5px'}}>
                             <ItemHeading>{reFormat((key))}</ItemHeading>
                             <ItemText>{`${key==='price'?'R':''}${itemToView[key]}`}</ItemText>
                    </div>
                )
                })
                )
                setOpenDialog(true)
            }
        }, [itemToView])
        useEffect(()=>{
            if(itemToRemove) {
                setDialogAction('confirm_removal')
                setDialogTitle("Removal from cart")
                setDialogDescription(`Are you sure you want to remove ${cart.items[itemToRemove].details.name} (${itemToRemove}) from you cart?`)
                setOpenDialog(true)
            }
        }, [itemToRemove])
        const onViewItem = (item)=>{
            setItemToView(item)
        }
        const scanAnotherItem = ()=>{
            setItemToView({})
            navigate('/scan')
        }
        const inc = (nappi_code)=>{
            let cartCopy = JSON.parse(JSON.stringify(cart))
            const new_qty = cartCopy.items[nappi_code]['qty']+1
            if(new_qty>inventory[nappi_code]){
                setAlertProps({
                    type:'error',
                    message:'Max supply has been added to cart'
                })
                setSnackOpen(true)
                return
            }
            cartCopy.items[nappi_code]['qty'] = new_qty
            setCart(cartCopy)
        }
        const dec = (nappi_code)=>{
            let cartCopy = JSON.parse(JSON.stringify(cart))
            const new_qty = cartCopy.items[nappi_code]['qty']-1
            if(new_qty===0){
                setItemToRemove(nappi_code)
            }
            else {
                cartCopy.items[nappi_code]['qty'] = new_qty
                setCart(cartCopy)
            }
        }
        const onItemClose = ()=>{
            setItemToView({})
        }
        const removeItemFromCart = ()=>{
            let cartCopy = JSON.parse(JSON.stringify(cart))
            delete cartCopy.items[itemToRemove]
            setCart(cartCopy)
            setItemToRemove(null)
            if(getTotalCartCount(cartCopy)===0){
                setAlertProps({
                    type:'info',
                    message:'Cart is empty. Please proceed to scan items to add to cart'
                })
                setSnackOpen(true)
                navigate('/scan')
            }
        }
        const cancelRemoval = ()=>{
            setItemToRemove(null)
        }
        const getAction = (action) =>{
            switch (action){
                case 'item':
                    return onItemClose
                case 'confirm_removal':
                    return removeItemFromCart
                case 'checkout':
                    return dispense
                default:
                    return ()=>{console.log("No action")}
            }
        }
        const getCancelAction = (action) =>{
            switch (action){
                case 'item':
                    return null
                case 'confirm_removal':
                    return cancelRemoval
                default:
                    return ()=>{console.log("No action")}
            }
        }
        const triggerDispense = () =>{
            setDialogAction('checkout')
            setDialogTitle("Checkout")
            setDialogDescription(`Are you sure you want to checkout?`)
            setOpenDialog(true)
        }
        const dispense = ()=>{
            let inventoryCopy = JSON.parse(JSON.stringify(inventory))
            Object.keys(cart.items).forEach((item_nappi)=>{
                inventoryCopy[item_nappi] = inventoryCopy[item_nappi] - cart.items[item_nappi].qty
            })
            createReceipt(cart, (receipt)=>{setReceipt(receipt)})
            setCart({items:{}})
            setInventory(inventoryCopy)
            navigate("/dispensed-summary")
        }
    return(
        <MultiActionScreen>
            <HeadingDiv>
                <MainText>
                    Items to dispense
                </MainText>
            </HeadingDiv>
            <SummaryDiv>
                <div style={{display:'block', height:'100%', overflow:'scroll', overflowX:'hidden', padding:'5px'}}>
                {
                    Object.keys(cart.items).map((item_nappi)=>{
                        const details = cart.items[item_nappi]['details']
                        const qty = cart.items[item_nappi]['qty']
                        return(
                            // <Badge badgeContent={qty}
                            //         sx={{
                            //             width:'100%',
                            //             "& .MuiBadge-badge": {
                            //                 color: 'white',
                            //                 backgroundColor: '#4DD07F',
                            //                 border:'1px solid #4DD07F',
                            //                 width: '20px',
                            //                 height: '20px',
                            //                 borderRadius: '20px',
                            //                 transform: 'translate(5px, -5px)',
                            //
                            //               },
                            //         }}>


                                <SummaryItemPreviewDiv key={`${details.nappi_code}`}>
                                   <div
                                       style={{display:'block', gridTemplateRows:'auto', gridTemplateColumns:'auto', margin:'5px'}}
                                        onClick={()=>{onViewItem(details)}}>
                                             <ItemHeading size={'sm'}>{reFormat(details.name)} <br/> {details.nappi_code}</ItemHeading>
                                             <ItemText size={'sm'}>View Details</ItemText>
                                    </div>
                                    <SummaryQuantityDiv>
                                        <IconButton onClick={()=>{dec(item_nappi)}}>
                                            <div>
                                                <img src={decrement} width={'75%'} height={'75%'} style={{padding:'5%', maxWidth:'30px'}} alt={"decrement icon"}></img>
                                            </div>
                                        </IconButton>
                                        <ItemHeading position={'center'} style={{color:'#F2F2F2', backgroundColor:'#8F8F97', borderRadius:'20px'}}>
                                            {qty}
                                        </ItemHeading>
                                        <IconButton onClick={()=>{inc(item_nappi)}}>
                                            <div>
                                                <img src={increment} width={'75%'} height={'75%'} style={{padding:'5%', maxWidth:'30px'}} alt={"increment icon"}></img>
                                            </div>
                                        </IconButton>

                                    </SummaryQuantityDiv>
                                    <IconButton onClick={()=>{setItemToRemove(item_nappi)}}>
                                        <div>
                                            <img src={bin} width={'100%'} height={'100%'} style={{padding:'5%', maxWidth:'30px'}} alt={"remove icon"}></img>
                                        </div>
                                    </IconButton>
                                </SummaryItemPreviewDiv>
                            // </Badge>
                        )
                    })
                }
                </div>
                <OptionsDiv>
                    <OptionButton onClick={()=>{scanAnotherItem()}}>
                        <div>
                            <img src={scan} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"scan icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                               Scan another item
                            </OptionText>
                        </div>
                    </OptionButton>
                    <OptionButton onClick={()=>{triggerDispense()}} color={'inverse'}>
                        <div>
                            <img src={proceed} width={'40px'}  height={'40px'} style={{paddingLeft:'15%'}} alt={"dispense icon"}></img>
                        </div>
                        <div style={{display:'flex', justifyContent: "center", textAlign:"center", width:"100%"}}>
                            <OptionText>
                               Dispense
                            </OptionText>
                        </div>
                    </OptionButton>

                </OptionsDiv>
            </SummaryDiv>
            <CustomDialog
                type={dialogAction==="item"?"alert":"confirmation"}
                open={openDialog}
                setOpen={setOpenDialog}
                title={dialogTitle}
                description={dialogDescription}
                onConfirm={getAction(dialogAction)}
                onCancel={getCancelAction(dialogAction)}
            />

        </MultiActionScreen>
    )
}

export default ScannedSummary;