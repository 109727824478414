import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import MainMenu from "./pages/MainMenu";
import Unlock from "./pages/Unlock";
import Scan from "./pages/Scan";
import ScannedItem from "./pages/ScannedItem";
import ScannedSummary from "./pages/ScannedSummary";
import DispensedSummary from "./pages/DispensedSummary";
import Splash from "./pages/Splash";
import Login from "./pages/Login";
import {useState} from "react";
import {Alert, Badge, Grow, Snackbar} from "@mui/material";

const App = () => {
    const location = useLocation()
    const navigate = useNavigate()
    // TODO when scanning at item, make sure that it is part of the inventory (Stat is a dict with key=nappi and value=stock count)
    const [medicineInventory, setMedicineInventory] = useState(
        {
            '0000000001': 10,
            '0000000002': 10,
            '0000000003': 10,
        }
    )
    const [nappiDescriptions, setNappiDescriptions] = useState({
        '0000000001': "Panado Paracetamol 500mg 30 Tablets help with the relief of mild pains caused by headaches, toothaches and pains associated with colds and flu. It's relief you can trust for the whole family.",
        '0000000002': "Scott's Emulsion Cod Liver Oil Orange 100ml has been helping families stay healthy for over 100 years. It is made from cod liver and is high in vitamins A & D, which help with growth and development, and promote a healthy immune system.",
        '0000000003': "Allergex is used for treatment of symptoms of seasonal allergic rhinitis such as runny nose, itchy nose, sneezing, itchy or red throat, itchy eyes and watery eyes; Treatment of chronic idiopathic urticaria, urticaria with choline or idiopathic cold urticaria.",
    })
    const [scannedMedicine, setScannedMedicine] = useState({})
    const [cart, setCart] = useState({items:{}})
    const [doctorName, setDoctorName] = useState("Xavier")
    const [alertProps, setAlertProps] = useState({type:'info', message:'message'})
    const [snackOpen, setSnackOpen] = useState(false)
    const [receiptPDF, setReceiptPDF] = useState(null)

  return (
      <div>
      <Routes>
          <Route index element={<Splash/>}/>
          <Route path="access-menu" element={<MainMenu doctorName={doctorName}/>}/>
          <Route path="login" element={<Login setDoctorName={setDoctorName}/>}/>
          <Route path="unlock" element={<Unlock/>}/>
          <Route path="scan" element={
              <Scan
                  setScannedItem={setScannedMedicine}
                  scannedItem={scannedMedicine}
                  cart={cart}
                  nappiDescriptions={nappiDescriptions}
                  medicineInventory={medicineInventory}
                  setSnackOpen={setSnackOpen}
                  setAlertProps={setAlertProps}/>}/>
          <Route path="scanned-item" element={
              <ScannedItem
                  scannedItem={scannedMedicine}
                  setScannedItem={setScannedMedicine}
                  setCart={setCart}
                  cart={cart}
                  nappiDescriptions={nappiDescriptions}
                  setSnackOpen={setSnackOpen}
                  setAlertProps={setAlertProps}
              />}/>
          <Route path="scanned-summary" element={
              <ScannedSummary
                  setCart={setCart}
                  cart={cart}
                  inventory={medicineInventory}
                  setInventory={setMedicineInventory}
                  setSnackOpen={setSnackOpen}
                  setAlertProps={setAlertProps}
                  setReceipt={setReceiptPDF}/>}/>
          <Route path="dispensed-summary" element={
              <DispensedSummary
                receipt={receiptPDF}
                setReceipt={setReceiptPDF}/>}/>
      </Routes>
      <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={()=>{setSnackOpen(false)}}
          TransitionComponent={Grow}
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
      >
          <Alert onClose={()=>{setSnackOpen(false)}} severity={alertProps?.type} sx={{ width: '100%' }}>
              {alertProps?.message}
          </Alert>
      </Snackbar>
      </div>
  );
}

export default App;
